<template>
  <div>
    <div
      class="login-view d-flex justify-content-center align-items-center"
      v-if="!warranty && !serviceWorker"
    >
      <div>
        <b-card
          v-if="seminar"
          :title="seminar.title"
          tag="article"
          class="seminar__card my-2"
          :img-src="seminar.image"
          img-top
        >
          <b-card-text>
            <p style="color: black !important;">
              {{ seminar.description }}
            </p>

            <p style="color: black !important;">
              <span class="font-weight-bold">Начало:</span>
              {{ seminar.startDt }}
            </p>
          </b-card-text>

          <div class="text-center mt-4">
            <EsiaLoginButton
              size="sm"
              class-style="my-2"
              :forward="forward"
            />
          </div>
        </b-card>
      </div>

      <template v-if="userUuidOldProfiClub">
        <h5 class="mb-5">
          Регистрация в новом Профи-Клуб. Ваши бонусы будут сохранены!
        </h5>
      </template>

      <template v-if="!seminar && !userUuidOldProfiClub">
        <b-container class="h-100">
          <b-row class="h-100 login-container">
            <b-col
              cols="10"
              md="8"
              lg="4"
              xl="3"
              class="d-flex text-center text-primary flex-column justify-content-center"
            >
              <h2>КРУПНЕЙШЕЕ СООБЩЕСТВО</h2>
              <p>ПРОФЕССИОНАЛОВ</p>
              <b-button
                variant="secondary"
                size="lg"
                @click="serviceWorker = true"
              >
                Вступить
              </b-button>
            </b-col>
            <b-col
              cols="10"
              md="8"
              lg="4"
              xl="3"
              class="d-flex text-primary text-center flex-column justify-content-center"
            >
              <h2>ТЕХНИЧЕСКАЯ ПОДДЕРЖКА</h2>
              <p>ДЛЯ ВСЕХ</p>
              <b-button
                size="lg"
                variant="secondary"
                @click="technicalSupport = true"
              >
                Спросить
              </b-button>
            </b-col>
            <b-col
              cols="10"
              md="8"
              lg="4"
              xl="3"
              class="d-flex text-primary text-center flex-column justify-content-center"
            >
              <h2>РАСШИРЕННАЯ ГАРАНТИЯ</h2>
              <p>«ДЛЯ СВОИХ»</p>
              <b-button
                variant="secondary"
                size="lg"
                @click="warranty = true"
              >
                Получить
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </div>
    <div
      v-if="warranty"
      class="warranty__page"
    >
      <b-container class="h-100 d-flex flex-column justify-content-between">
        <b-row class="text-center text-light pt-5">
          <b-col class="h2">
            Как получить <br><span class="font-weight-bold">сертификат расширенной гарантии?</span>
          </b-col>
        </b-row>
        <b-row class="justify-content-between">
          <b-col
            cols="12"
            md="5"
          >
            <ul>
              <li class="d-flex align-items-center">
                <img
                  src="../assets/insurance(1).png"
                  alt="Зарегистрируйтесь в Профи-клубе"
                >
                <p class="ml-4">Зарегистрируйтесь в Профи-клубе</p>
              </li>
              <li class="d-flex align-items-center">
                <img
                  src="../assets/insurance(2).png"
                  alt="Введите серийный номер прибора или отсканируйте qr-код"
                  class="middle-img"
                >
                <p class="ml-4">Введите серийный номер прибора или отсканируйте qr-код</p>
              </li>
              <li class="d-flex align-items-center">
                <img
                  src="../assets/insurance(3).png"
                  alt="Скачайте сертификат в разделе «устройства»"
                >
                <p class="ml-4">Скачайте сертификат в разделе «устройства»</p>
              </li>
            </ul>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center"
          >
            <div class="sticker"/>
          </b-col>
        </b-row>
        <b-row class="justify-content-center pt-5 pb-5">
          <EsiaLoginButton
            button-text="Получить гарантию"
            class-style="btn btn-secondary text-center"
            size="lg"
          />
        </b-row>
      </b-container>
    </div>
    <div
      class="service-worker__page"
      v-if="serviceWorker"
    >
      <b-container>
        <b-row>
          <b-col
            cols="12"
            class="text-center mt-5"
          >
            <h2>Профи-клуб — это одно из крупнейших профессиональных
              сообществ монтажников и проектировщиков</h2>
            <p style="font-size: 20px; font-weight: 500;">Не хватает только вас!</p>
          </b-col>
        </b-row>
        <b-row
          cols="2"
          cols-sm="3"
          cols-lg="3"
          cols-xl="6"
          class="benefits">
          <b-col
            v-for="card in benefits"
            :key="card.id"
          >
            <div class="card h-100 d-flex text-center justify-content-center align-items-center">
              <img :src="card.image">
              <p class="mb-0 p-2">{{ card.title }}</p>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-center pt-5">
          <EsiaLoginButton
            forward="/home?clicked-the-professional-member-button-on-the-registration"
            button-text="Регистрация"
            class-style="btn btn-secondary text-center"
            size="lg"
          />
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="text-primary text-center pt-5"
          >
            <p class="mb-0">Что профессионалы говорят</p>
            <p class="font-weight-bold">о Профи-клубе</p>
            <b-icon icon="arrow-down"/>
          </b-col>
        </b-row>
        <b-row class="service-worker__page__chel">
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-center mb-5 mb-md-0"
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ERMxEaZ8RwE?autoplay=0&showinfo=0&controls=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media;
                gyroscope; picture-in-picture"
              allowfullscreen
              frameborder="0"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-end"
          >
            <img
              src="../assets/finger-left.png"
              class="person_finger_left"
              width="100%"
            >

            <img
              src="../assets/finger-top.png"
              class="person_finger_top"
              width="100%"
            >
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal
      v-model="technicalSupport"
      hide-footer
      size="lg"
      centered
    >
      <div class="text-center">
        <h2>Техническая поддержка</h2>
        <p><i>Напишите нам и мы решим вашу проблему!</i></p>
      </div>
      <b-container class="h-100" style="margin: 50px 0">
        <b-row class="h-100 d-flex justify-content-between">
          <b-col
            class="d-flex text-primary flex-column"
          >
            <a
              href="https://wa.me/79289064683"
              target="_blank"
              class="text-center"
            >
              <WhatsApp width="70" style="max-width: 100%;" viewBox="0 0 128 128"/>
              <p style="color:#000; margin-top: 8px">WhatsApp</p>
            </a>
          </b-col>
          <b-col
            class="d-flex text-primary flex-column"
          >
            <a
              href="https://t.me/+79289064683"
              target="_blank"
              class="text-center"
            >
              <Telegram width="70" style="max-width: 100%;" viewBox="0 0 128 128"/>
              <p style="color:#000; margin-top: 8px">Telegram</p>
            </a>
          </b-col>
          <b-col
            class="d-flex text-primary flex-column"
          >
            <a
              href="mailto:911@bast.ru"
              target="_blank"
              class="text-center"
            >
              <Email width="70" style="max-width: 100%;" viewBox="0 0 128 128"/>
              <p style="color:#000; margin-top: 8px">Email</p>
            </a>

          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import EsiaLoginButton from '../components/EsiaLoginButton.vue';
import { getSeminar } from '../api/profi_club/seminars';
import WhatsApp from '../assets/whats-app.svg';
import Telegram from '../assets/telegram.svg';
import Email from '../assets/email_logo.svg';

export default {
  name: 'Login',

  components: {
    WhatsApp,
    Telegram,
    Email,
    EsiaLoginButton,
  },

  data: () => ({
    textOffer: 'Чтобы получить бонусы и пожизненную гарантию, Вам необходимо войти/зарегистрироваться в Профи-клуб Бастион.',
    textAfterLogin: 'Вы будете перенаправлены на единый сервис регистрации Бастион',
    warranty: false,
    serviceWorker: false,
    seminar: null,
    technicalSupport: false,
    benefits: {
      1: {
        // eslint-disable-next-line global-require
        image: require('../assets/benefit1.png'),
        title: 'Прямой разговор с производителем',
      },
      2: {
        // eslint-disable-next-line global-require
        image: require('../assets/benefit2.png'),
        title: 'Индивидуальная поддержка',
      },
      3: {
        // eslint-disable-next-line global-require
        image: require('../assets/benefit3.png'),
        title: 'Общение с коллегами',
      },
      4: {
        // eslint-disable-next-line global-require
        image: require('../assets/benefit4.png'),
        title: 'Бесплатное обучение',
      },
      5: {
        // eslint-disable-next-line global-require
        image: require('../assets/benefit5.png'),
        title: 'Бонусы и подарки',
      },
      6: {
        // eslint-disable-next-line global-require
        image: require('../assets/benefit6.png'),
        title: 'Закрытые мероприятия',
      },
    },
  }),

  methods: {
    async getSeminarById() {
      try {
        const seminarId = this.forward.slice('/join-to-seminar/'.length);

        this.seminar = await getSeminar(seminarId);
      } catch (error) {
        this.initPageServerErrorMessage = error.response.data.detail || 'Произошла ошибка (семинар)';
      } finally {
        this.isLoading = false;
      }
    },
  },

  computed: {
    forward() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'forward')) {
        // Прокидываем путь для редиректа при успешной авторизации
        return this.$route.query.forward;
      }
      return null;
    },

    userUuidOldProfiClub() {
      const url = new URL(this.$route.query.forward, 'http://localhost:8080');
      return url.searchParams.get('userUuidOldProfiClub');
    },
  },

  async mounted() {
    if (this.forward && this.forward.includes('/join-to-seminar/')) {
      await this.getSeminarById();
    }
  },
};
</script>

<style lang="scss">
@import '../assets/_variables.scss';

.login-view {
  position: relative;
  //noinspection CssUnknownTarget
  background-image: url('/img/login-choice.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;

  @media (max-width: 992px) {
    h2, p {
      color: #fff !important;
    }
  }
}

.login-view::before {
  content: none;
  background: #fff;

  @media (min-width: 992px) {
    content: '';
    //noinspection CssUnknownTarget
    background-image: url('/img/wave.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.seminar__card {
  width: 300px;
  box-shadow: 0px 5px 8px 2px rgba(8, 47, 136, 0.19);
}

.login-container {
  padding: 20px 0;
  justify-content: space-between;

  @media (max-width: 992px) {
    justify-content: center;
  }
  @media (max-width: 576px) {
    gap: 30px;
  }
}

@media (min-width: 768px) {
  .seminar__card {
    width: 400px;
  }
}

.sticker {
  background-image: url('../assets/sticker.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 200px;
}

.service-worker__page {
  position: relative;
  background: url('../assets/Rectangle1.png') right bottom no-repeat,
  url('../assets/Rectangle2.png') left top -50% no-repeat;

  h2 {
    font-size: 30px;
  }

  h2, p {
    color: $bastion-blue-color;
  }

  .benefits {
    .card {
      padding: 50px 40px 40px 40px;
      border-radius: 10px;

      p {
        margin-top: 32px;
      }

      img {
        width: 64px;
        height: 64px;
      }
    }

    .col {
      margin-top: 20px;
    }
  }

  @media (max-width: 786px) {
    background: none;
  }
}

.warranty__page {
  height: 100%;
  background-image: url('../assets/insurance(background).jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ul {
    list-style-type: none;
    color: #fff;

    li {
      margin-bottom: 53px;

      img {
        max-width: 90px;
      }
    }
  }

  @media (max-width: 786px) {
    .h2 {
      font-size: 20px;
      margin-bottom: 55px;
    }
    ul {
      img {
        max-width: 40px !important;
      }
    }
  }
}

@media (min-width: 768px) {
  .middle-img {
    margin-right: 1rem;
  }
}
</style>

<style>
@media (max-width: 760px) {
  .person_finger_left {
    display: none;
  }
}

@media (min-width: 760px) {
  .person_finger_top {
    display: none;
  }
}
</style>
